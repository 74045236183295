define("discourse/plugins/discourse-nationalflags/discourse/components/user-nationalflags-preferences", ["exports", "discourse-common/utils/decorators", "@ember/component"], function (_exports, _decorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    layoutName: 'javascripts/discourse/templates/connectors/user-custom-preferences/user-nationalflags-preferences',
    flagsource() {
      return this.get('model.custom_fields.nationalflag_iso') == null ? 'none' : this.get('model.custom_fields.nationalflag_iso');
    }
  }, [["method", "flagsource", [(0, _decorators.default)('model.custom_fields.nationalflag_iso')]]]));
});