define("discourse/plugins/discourse-nationalflags/discourse/templates/connectors/user-profile-primary/show-user-card", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.custom_fields.nationalflag_iso}}
    <div>
      <img class="nationalflag-usersummary" src="/plugins/discourse-nationalflags/images/nationalflags/{{model.custom_fields.nationalflag_iso}}.png" />
    </div>
  {{/if}}
  
  */
  {
    "id": "5vbpsxbx",
    "block": "[[[41,[30,0,[\"model\",\"custom_fields\",\"nationalflag_iso\"]],[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[44,[[28,[37,2],null,[[\"model\"],[[28,[32,0],[\"model\"],null]]]]],[[[10,\"img\"],[14,0,\"nationalflag-usersummary\"],[15,\"src\",[29,[\"/plugins/discourse-nationalflags/images/nationalflags/\",[52,[30,1,[\"model\"]],[28,[30,1,[\"model\"]],null,null],[28,[32,1],[[30,0],\"model.custom_fields.nationalflag_iso\",\"[\\\"The `model` property path was used in the `discourse/plugins/discourse-nationalflags/discourse/templates/connectors/user-profile-primary/show-user-card.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]],\".png\"]]],[12],[13]],[1]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,2],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-nationalflags/discourse/templates/connectors/user-profile-primary/show-user-card.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-nationalflags/discourse/templates/connectors/user-profile-primary/show-user-card.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});