define("discourse/plugins/discourse-nationalflags/wizard/components/wizard-field-national-flag", ["exports", "ember-addons/ember-computed-decorators", "wizard/lib/ajax"], function (_exports, _emberComputedDecorators, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(dt7948.p({
    layoutName: 'javascripts/wizard/templates/components/wizard-field-national-flag',
    natflaglist: [],
    setup() {
      (0, _ajax.ajax)({
        url: '/natflags/flags',
        type: 'GET'
      }).then(natflags => {
        let localised_flags = [];
        localised_flags = natflags.flags.map(element => {
          return {
            code: element.code,
            pic: element.pic,
            description: I18n.t(`flags.description.${element.code}`)
          };
        }).sortBy('description');
        this.set('natflaglist', localised_flags);
      });
    },
    flagsource() {
      return this.get('field.value') == null ? 'none' : this.get('field.value');
    }
  }, [["method", "setup", [(0, _emberComputedDecorators.on)('init')]], ["method", "flagsource", [(0, _emberComputedDecorators.default)('field.value')]]]));
});